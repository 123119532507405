footer {
  padding: 3rem 0.5rem 2rem;
  font-size: 1rem;
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
}
footer .container {
  text-align: center;
  display: grid;
  align-items: center;
}

.footer-social_links {
  display: flex;
  justify-content: center;
}
.footer-social_links a {
  width: 60px;
}

.footer-content {
  display: grid;
}
.footer-content p:nth-child(1),
.footer-content p:nth-child(3) {
  font-weight: 600;
}

@media screen and (min-width: 764px) {
  footer .container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footer-social_links {
    justify-content: flex-start;
  }
}
