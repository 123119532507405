.alert {
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s;
  opacity: 0;
  pointer-events: none;
}

.alert.show {
  opacity: 1;
  pointer-events: visible;
}

@media screen and (min-width: 556px) {
  .alert.game-page {
    top: 86px;
  }
}
