.hero_info {
  max-width: 1024px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.hero_info h1 {
  margin-bottom: 20px;
  font-size: var(--text-large);
  font-weight: 600;
}

.hero_info p {
  margin-bottom: 20px;
  font-size: var(--text-medium);
}

.hero_info p > a {
  color: var(--clr-light-green);
  text-decoration: underline;
}

.hero_info p:last-child {
  margin: 0;
}
